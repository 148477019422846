import Head from "next/head";
import Link from "next/link";

export default function FourOFour(){
    return (
      <>
        <div
          style={{
            textAlign: "center",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <h1>404</h1>
          <h2>Oops! Page Not Found</h2>
          <p>
            Sorry but the page you are looking for does not exist, have been
            removed. name changed or is temporarily unavailable
          </p>
          <Link href='/' legacyBehavior>
            <a>Back to homepage</a>
          </Link>
        </div>
      </>
    );
}